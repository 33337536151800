<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5">
            <v-col cols="6">
                <div class="title-section">
                    MI CUENTA
                </div>
            </v-col>
            <v-col cols="6" align="right">
                <v-btn class="normal-btn black--text mr-2" :disabled="loadingSave" color="secondary" depressed @click="cancelEdit" v-if="editMode">
                    <v-icon left>mdi-close-circle-outline</v-icon>
                    Cancelar
                </v-btn>
                <v-btn class="normal-btn" :loading="loadingSave" :color="editMode ? 'success' : 'primary'" depressed @click="setEditMode">
                    <template v-if="!editMode">
                        <v-icon left>mdi-pencil-circle-outline</v-icon>
                        Editar
                    </template>
                    <template v-else>
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Guardar
                    </template>
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="mb-5">
            <v-col cols="6">
                <kimsa-text-field
                    label="Nombre"
                    bolder-label
                    solo flat dense
                    :value="name"
                    :readonly="!editMode"
                    outlined
                    @change="name = $event"
                />
            </v-col>
            <v-col cols="6">
                <kimsa-text-field
                    label="Correo"
                    bolder-label
                    solo flat dense
                    :value="email"
                    :readonly="!editMode"
                    outlined
                    @change="email = $event"
                />
            </v-col>
            <v-col cols="6" v-if="isAdmin">
                <kimsa-text-field
                    v-if="!editMode"
                    label="Rol"
                    bolder-label
                    solo flat dense
                    :value="role"
                    readonly
                    outlined
                />
                <kimsa-select
                    v-else
                    label="Rol"
                    bolder-label
                    solo flat dense
                    outlined
                    item-value="id"
                    item-text="name"
                    :value="roleId"
                    :items="rolesOptions"
                    @change="roleId = $event"
                />
            </v-col>
            <v-col cols="6">
                <kimsa-date-picker
                    label="Fecha de Nacimiento"
                    bolder-label
                    placeholder="Sin Fecha de Nacimiento"
                    solo flat dense
                    birth-date-mode
                    :value="birthDate"
                    :disabled="!editMode"
                    outlined
                    min="1900-01-01"
                    max="2020-12-31"
                    @change="birthDate = $event"
                />
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import Axios from "@/utils/Axios";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import {mapMutations} from "vuex";
export default {
    name: "Account",
    components: {KimsaDatePicker, KimsaSelect, KimsaTextField},
    data() {
        return {
            editMode: false,
            loadingSave: false,
            rolesOptions: [],
            name: '',
            email: '',
            role: '',
            roleId: '',
            birthDate: '',
        }
    },
    async mounted() {
        const vm = this
        await vm.loadRoles()
        await vm.loadUser()
    },
    methods: {
        ...mapMutations(['setAccount']),
        setEditMode() {
            const vm = this
            vm.editMode = !vm.editMode
            if(!vm.editMode) vm.editAccount()
        },
        loadUser() {
            const vm = this
            vm.name = vm.account.name
            vm.email = vm.account.email
            vm.role = vm.account.role?.name || null
            vm.roleId = vm.account.role_id || null
            vm.birthDate = vm.account.birth_date && vm.$moment(vm.account.birth_date).format('YYYY-MM-DD') || null
        },
        cancelEdit() {
            const vm = this
            vm.editMode = false
            vm.loadUser()
        },
        async editAccount() {
            const vm = this
            vm.loadingSave = true

            let payload = {
                role_id: vm.roleId,
                name: vm.name,
                email: vm.email,
                birth_date: vm.birthDate,
            }

            vm.role = vm.rolesOptions.find(role => vm.roleId === role.id).name
            // console.log('payload', payload)

            await Axios.post('auth/update', payload).then(res => {
                // console.log('res editAccount', res)
                vm.setAccount(res.data.user)
                vm.loadUser()
                vm.toast(res.data.message)
            }).catch(error => {
                console.log('error editAccount', error)
            }).finally(() => {
                vm.loadingSave = false
            })
        },
        async loadRoles() {
            const vm = this
            await Axios.get('roles').then(res => {
                // console.log('res loadRoles', res)
                vm.rolesOptions = res.data.result.roles
            }).catch(error => {
                console.log('error loadRoles', error)
            })
        }
    },
}
</script>

<style scoped>

.title-section {
    color: var(--v-primary-base);
}

</style>